<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item>票据查询 </el-breadcrumb-item>
			<el-breadcrumb-item>应收票据</el-breadcrumb-item>
		</el-breadcrumb>
		<!-- tab -->
		<el-tabs v-model="activeName" 
						 type="card" 
						 @tab-click="handleTabClick"
						 class="mt30">
			<el-tab-pane label="持有票据" name="holdbill">
				<hold-bill @handleExportExcel="ExportExcel"></hold-bill>
			</el-tab-pane>
			<el-tab-pane label="已结清" name="endorse">
				<cleared></cleared>
			</el-tab-pane>
			<el-tab-pane label="已转出" name="rollout">
				<roll-out></roll-out>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>
<script>
	import HoldBill from './children/HoldBill.vue' // 持有票据
	import Cleared from './children/Cleared.vue' // 已结清
	import RollOut from './children/RollOut.vue' // 已转出
  export default {
    name: "Receivable",
		components: {
			HoldBill,
			Cleared,
			RollOut
		},
		data(){
			return {
				activeName: 'holdbill', // 
			}
		},
		methods: {
			// 导出Excel
			async ExportExcel(){
				const res = await this.$auth.get('/cpiaoju-ticket/credit/ticket/export/excel', {responseType: 'blob'})
				if(res.headers['content-disposition']){
					const fileName = window.decodeURI(res.headers['content-disposition'].split(';')[1].split('=')[1].slice(7))
					let a = document.createElement('a')
					let url = URL.createObjectURL(res.data)
					a.href = url
					a.download = fileName
					a.click()
					URL.revokeObjectURL(url)
				}else{
					console.log(res.data.msg)
				}
			},
			handleTabClick(tab, event) {
        // console.log(tab, event);
      }
		}
  }
</script>

<style lang="less" scoped>
	.search-form{
		/deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner{
			width: 100% !important;
		}
		.data-gap{
			display: inline-block;
			margin: 0 5px;
		}
		.special-form-item{
			/deep/ .el-form-item__content{
				display: flex;
			}
		}
		.el-select{
			width: 100%;
		}
		.btn-form-item{
			text-align: center;
			.el-button{
				width: 160px;
			}
		}
	}
	.el-card{
		margin-top: 0;
	}
</style>
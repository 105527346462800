<template>
  <el-card>
    <!--查询-->
    <el-form ref="searchFormRef"
              :model="searchForm"
              label-width="110px"
              class="search-form">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="票据包号：">
            <el-input v-model="searchForm.packageNo"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="票据包金额：" class="special-form-item">
            <el-input v-model="searchForm.packageSumMin" class="special-input"></el-input>
            <span class="data-gap">至</span>
            <el-input v-model="searchForm.packageSumMax" class="special-input"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="出票人：">
            <el-input v-model="searchForm.maker"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="出票日期：" class="special-form-item">
            <el-date-picker
                    v-model="searchForm.applyDateStart"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
            </el-date-picker>
            <span class="data-gap">至</span>
            <el-date-picker
                    v-model="searchForm.applyDateEnd"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="承兑人：">
            <el-input v-model="searchForm.acceptor"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="到期日期：" class="special-form-item">
            <el-date-picker
                    v-model="searchForm.expireDateStart"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
            </el-date-picker>
            <span class="data-gap">至</span>
            <el-date-picker
                    v-model="searchForm.expireDateEnd"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="票据种类：">
            <el-select v-model="searchForm.ticketType" placeholder="请选择">
              <el-option label="银票" value="AC01"></el-option>
              <el-option label="商票" value="AC02"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="票据结算日期：" class="special-form-item">
            <el-date-picker
                    v-model="searchForm.settledDateStart"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
            </el-date-picker>
            <span class="data-gap">至</span>
            <el-date-picker
                    v-model="searchForm.settledDateEnd"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item class="btn-form-item">
          <el-button type="primary" @click="getTableList">查询</el-button>
          <el-button @click="handleReset">重置</el-button>
        </el-form-item>
      </el-row>
    </el-form>
     <!--列表-->
     <div class="bill-package">
      <p>票据包金额汇总（元）：<strong>{{totalPackageSum}}</strong></p>
      <el-button type="primary" @click="handleExportExcel">导出excel</el-button>
    </div>
    <el-table :data="tableList" border>
      <el-table-column prop="packageNo" label="票据包号" min-width="150px"></el-table-column>
      <el-table-column prop="ticketRange" label="子票区间" min-width="130px"></el-table-column>
      <el-table-column prop="packageSum" label="票据包金额（元）" min-width="140px"></el-table-column>
      <el-table-column prop="acceptor" label="承兑人" min-width="200px"></el-table-column>
      <el-table-column prop="acceptorAcctNo" label="承兑人账户" min-width="160px"></el-table-column>
      <el-table-column prop="acceptorAcctBankName" label="承兑人开户行" min-width="120px"></el-table-column>
      <el-table-column prop="acceptorAcctBankNo" label="承兑人开户行行号" min-width="150px"></el-table-column>
      <el-table-column prop="maker" label="出票人" min-width="160px"></el-table-column>
      <el-table-column prop="payeeAcctNo" label="收款账户" min-width="160px"></el-table-column>
      <el-table-column prop="payeeAcctBankName" label="收款开户行" min-width="150px"></el-table-column>
      <el-table-column prop="payeeAcctBankNo" label="收款开户行行号" min-width="150px"></el-table-column>
      <el-table-column prop="applyDate" label="出票日期" min-width="120px"></el-table-column>
      <el-table-column prop="expireDate" label="票据到期日" min-width="120px"></el-table-column>
      <el-table-column prop="settledDate" label="票据结清日" min-width="120px"></el-table-column>
      <el-table-column prop="ticketType" label="票据种类" min-width="100px"></el-table-column>
      <el-table-column prop="" label="操作" fixed="right" min-width="200px">
        <template v-slot="scope">
          <el-button type="primary" size="mini" @click="handleFaceView(scope.row)">
            票面预览
          </el-button>
          <el-button type="primary" size="mini" @click="handleFaceDownload(scope.row)">
            票面下载
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
export default {
  name: 'Cleared',
  mounted(){
    // 获取列表数据
    this.getTableList()
  },
  data(){
    return {
      // 搜索表单数据
      searchForm: {
        packageNo: '', //票据包号
        ticketType: '', //票据种类(AC01：银票、AC02：商票)
        acceptor: '', //承兑人
        maker: '', //出票人
        applyDateStart: '',    //出票日期 起始日期
        applyDateEnd: '',      //出票日期 结束日期
        packageSumMin: '',     //票据包金额 min
        packageSumMax: '',     //票据包金额 max
        expireDateStart: '',   //到期日期 起始日期
        expireDateEnd: '',     //到期日期 结束日期
        settledDateStart: '',  //票据结清日 起始日期	
        settledDateEnd:'',     //票据结清日 结束日期	
        pageSize: 10,  //当前页面数据量
        pageNum: 1,    //当前页码
      },
      total: 0, // 总数
      tableList: [], //列表数据
    }
  },
  computed: {
    //票据包金额汇总（元）
    totalPackageSum(){
      return this.tableList.reduce((previousValue, currentValue)=>{
        return previousValue + currentValue.packageSum
      },0)
    }
  },
  methods: {
    // 获取列表数据
    async getTableList(){
      const {data: res} = await this.$auth.get('/cpiaoju-ticket/credit/ticket/settled/page', {params: this.searchForm})
      if(res.code !== 200) return this.$message.error(res.msg)
      this.tableList = res.data.records;
      this.total = res.data.total
    },
    // 重置
    handleReset(){
      this.searchForm.packageNo = '' //票据包号
      this.searchForm.ticketType = '' //票据种类(AC01：银票、AC02：商票)
      this.searchForm.acceptor = ''//承兑人
      this.searchForm.maker = '' //出票人
      this.searchForm.applyDateStart = ''//出票日期 起始日期
      this.searchForm.applyDateEnd = '' //出票日期 结束日期
      this.searchForm.packageSumMin = '' //票据包金额 min
      this.searchForm.packageSumMax = '' //票据包金额 max
      this.searchForm.expireDateStart = ''//到期日期 起始日期
      this.searchForm.expireDateEnd = '' //到期日期 结束日期
      this.searchForm.settledDateStart = '' //票据结清日 起始日期	
      this.searchForm.settledDateEnd = '' //票据结清日 结束日期	
      this.getTableList()
    },
    // 票面预览
    async handleFaceView(row){
      let params = {
        paperBagNo: row.packageNo,
        section: row.ticketRange
      }
      const res = await this.$auth.post('/cpiaoju-ticket/common/ticketFaceView', this.$qs.stringify(params), {responseType: 'blob'});
      if (res.data.type === "application/pdf"){
        const blob = res.data;
        let link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.href = window.URL.createObjectURL(blob);
        link.click()
        URL.revokeObjectURL(link.href);
      }else{
        return this.$message.error('暂无票面信息')
      }
    },
    //票面下载
    async handleFaceDownload(row){
      let params = {
        paperBagNo: row.packageNo,
        section: row.ticketRange
      }
      const res = await this.$auth.post('/cpiaoju-ticket/common/ticketFaceDownload', this.$qs.stringify(params), {responseType: 'blob'});
      console.log(res)
      if (res.headers['content-disposition']){
        const blob = res.data;
        const fileName = window.decodeURI(res.headers['content-disposition'].split(';')[1].split('=')[1]); //这是下载的关键
        let link = document.createElement('a');
        let url = URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName
        console.log(link)
        link.click()
        URL.revokeObjectURL(url)
      }else{
        return this.$message.error('暂无票面信息')
      }
    },
    // 导出Excel
    handleExportExcel(){
      this.$emit('handleExportExcel')
    }
  }
}
</script>

<style lang="less" scoped>
  .search-form{
		/deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner{
			width: 100% !important;
		}
		.data-gap{
			display: inline-block;
			margin: 0 5px;
		}
		.special-form-item{
			/deep/ .el-form-item__content{
				display: flex;
			}
		}
		.el-select{
			width: 100%;
		}
		.btn-form-item{
			text-align: center;
			.el-button{
				width: 160px;
			}
		}
	}
  // 票据包金额总汇
	.bill-package{
		background-color: #fdf6ec;
    color: #E6A23C;
		padding: 10px 20px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    p{
      line-height: 40px;
    }
	}
</style>